var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title"},[_c('i',{staticClass:"el-icon-arrow-left icon",on:{"click":function($event){return _vm.$router.go(-1)}}}),_vm._v("新建任务 ")]),_c('div',{staticClass:"main"},[_c('el-card',{staticClass:"box-card"},[_c('p',{staticClass:"tiltle"},[_vm._v(" 发布任务需要当前有生效中的合同，如果当前无生效中的合同是无法发布成功任务的。任务发布后,执行中的任务自动展示在雲途志小程序，符合相关行业的个体工商户则可以领取该任务 ")]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"任务名称","prop":"taskName"}},[_c('el-input',{staticStyle:{"width":"400px"},attrs:{"maxlength":"30","placeholder":"请输入任务名称"},model:{value:(_vm.ruleForm.taskName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "taskName", $$v)},expression:"ruleForm.taskName"}})],1),_c('el-form-item',{attrs:{"label":"任务开始日期","prop":"value1","rules":[
            {
              required: true,
              message: '请输入任务开始日期',
              trigger: 'change',
            } ]}},[_c('el-date-picker',{staticStyle:{"width":"400px"},attrs:{"value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions0,"type":"date","placeholder":"选择日期"},model:{value:(_vm.ruleForm.value1),callback:function ($$v) {_vm.$set(_vm.ruleForm, "value1", $$v)},expression:"ruleForm.value1"}})],1),_c('el-form-item',{attrs:{"label":"任务结束日期","prop":"value2","rules":[
            {
              required: true,
              message: '请输入任务结束日期',
              trigger: 'change',
            } ]}},[_c('el-date-picker',{staticStyle:{"width":"400px"},attrs:{"value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions1,"type":"date","placeholder":"选择日期"},model:{value:(_vm.ruleForm.value2),callback:function ($$v) {_vm.$set(_vm.ruleForm, "value2", $$v)},expression:"ruleForm.value2"}})],1),_c('el-form-item',{attrs:{"label":"结算周期","prop":"settlementCycleId","rules":[
            { required: true, message: '请选择结算周期', trigger: 'change' } ]}},[_c('el-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请选择结算周期","clearable":""},model:{value:(_vm.ruleForm.settlementCycleId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "settlementCycleId", $$v)},expression:"ruleForm.settlementCycleId"}},_vm._l((_vm.settlementCycleArr),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"结算标准","prop":"settlementStandardId","rules":[
            { required: true, message: '请选择结算标准', trigger: 'change' } ]}},[_c('el-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请选择结算标准","clearable":""},model:{value:(_vm.ruleForm.settlementStandardId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "settlementStandardId", $$v)},expression:"ruleForm.settlementStandardId"}},_vm._l((_vm.settlementStandardArr),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"结算单价","prop":"price"}},[_c('el-input-number',{staticStyle:{"width":"200px"},attrs:{"min":0,"controls":false,"precision":2},model:{value:(_vm.ruleForm.price),callback:function ($$v) {_vm.$set(_vm.ruleForm, "price", $$v)},expression:"ruleForm.price"}})],1),_c('el-form-item',{attrs:{"label":"任务模板"}},_vm._l((_vm.taskDescribeArr),function(item,index){return _c('div',{key:index,staticClass:"templateClass",class:{ activecolor: _vm.isactive == index },on:{"click":function($event){return _vm.activeClick(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('el-form-item',{attrs:{"label":"任务描述","prop":"taskDescribe"}},[_c('el-input',{attrs:{"type":"textarea","disabled":""},model:{value:(_vm.ruleForm.taskDescribe),callback:function ($$v) {_vm.$set(_vm.ruleForm, "taskDescribe", $$v)},expression:"ruleForm.taskDescribe"}})],1),_c('el-form-item',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary","disabled":_vm.isDisable},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("确认发布")]),(_vm.ifmsg)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }