<template>
  <div>
    <div class="title">
      <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>新建任务
    </div>
    <div class="main">
      <el-card class="box-card">
        <p class="tiltle">
          发布任务需要当前有生效中的合同，如果当前无生效中的合同是无法发布成功任务的。任务发布后,执行中的任务自动展示在雲途志小程序，符合相关行业的个体工商户则可以领取该任务
        </p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="任务名称" prop="taskName">
            <el-input
              v-model="ruleForm.taskName"
              style="width: 400px"
              maxlength="30"
              placeholder="请输入任务名称"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="所属行业" prop="region1">
                <el-select v-model="ruleForm.region" placeholder="请选择所属行业" ref="nationalityIdSel" @change="Industrychange()">
                    <el-option :label="item.scopeLabel" :value="item.scopeValue" v-for="item in Industrylist" :key="item.scopeValue"></el-option>
                </el-select>
                 <el-select v-model="ruleForm.region1" placeholder="请选择所属行业" ref="nationalityIdSel1" style="margin-left:20px" :disabled="dis"  @change="IndustrychangeChild()">
                    <el-option  :label="item.industryLabel" :value="item.industryValue" v-for="item in IndustrylistChild" :key="item.scopeValue" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="行业编码" required>
                <el-input v-model="ruleForm.industryCode" style="width:400px" disabled></el-input>
            </el-form-item>
            <el-form-item label="开票类目" required>
                <el-input v-model="ruleForm.BillingType" style="width:400px" disabled></el-input>
            </el-form-item> -->
          <el-form-item
            label="任务开始日期"
            prop="value1"
            :rules="[
              {
                required: true,
                message: '请输入任务开始日期',
                trigger: 'change',
              },
            ]"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions0"
              v-model="ruleForm.value1"
              type="date"
              style="width: 400px"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="任务结束日期"
            prop="value2"
            :rules="[
              {
                required: true,
                message: '请输入任务结束日期',
                trigger: 'change',
              },
            ]"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions1"
              v-model="ruleForm.value2"
              type="date"
              style="width: 400px"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="任务类型" required>
               <div class="type">不限</div>
            </el-form-item> -->
          <el-form-item
            label="结算周期"
            prop="settlementCycleId"
            :rules="[
              { required: true, message: '请选择结算周期', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="ruleForm.settlementCycleId"
              placeholder="请选择结算周期"
              style="width: 200px"
              clearable
            >
              <el-option
                v-for="item in settlementCycleArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="结算标准"
            prop="settlementStandardId"
            :rules="[
              { required: true, message: '请选择结算标准', trigger: 'change' },
            ]"
          >
            <el-select
              v-model="ruleForm.settlementStandardId"
              placeholder="请选择结算标准"
              style="width: 200px"
              clearable
            >
              <el-option
                v-for="item in settlementStandardArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结算单价" prop="price">
            <el-input-number
              v-model="ruleForm.price"
              :min="0"
              :controls="false"
              :precision="2"
              style="width: 200px"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="任务模板">
            <div
              v-for="(item, index) in taskDescribeArr"
              :key="index"
              class="templateClass"
              @click="activeClick(index)"
              :class="{ activecolor: isactive == index }"
            >
              {{ item }}
            </div>
            <!-- <p v-html="a" style="" >{{a}}</p> -->
          </el-form-item>
          <el-form-item label="任务描述" prop="taskDescribe">
            <el-input
              type="textarea"
              v-model="ruleForm.taskDescribe"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: center; margin-top: 20px">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              :disabled="isDisable"
              >确认发布</el-button
            >
            <div style="color: red" v-if="ifmsg">{{ msg }}</div>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Industry, getDownBoxByType, createdTask } from "@/api/task";
import { isMobile } from "@/utils/validate";
export default {
  data() {
    var validateSpecial = (rule, value, callback) => {
      if (!this.checkSpecialKey(value)) {
        callback(new Error("不能含有特殊字符！！"));
      } else {
        callback();
      }
      if (isMobile(value)) {
        this.ruleForm.taskName = "";
      } else {
        callback();
      }
    };
    return {
      isDisable: false,
      // dis:true,//所属行业禁用
      // Industrylist:[],//收支类型下拉框
      // IndustrylistChild:[],//收支类型下拉框 二级
      ruleForm: {
        taskName: "",
        name: "",
        region: "",
        region1: "",
        // industryCode:"",//行业编码
        // BillingType:"",//开票类型
        value1: "", //开始时间
        value2: "", //结束日期
        settlementCycleId: "", //结算周期
        settlementStandardId: "", //计算标准
        price: undefined, //单价
        taskDescribe: "", //任务描述
      },
      value1: "",
      value2: "", //合同时间
      settlementCycleArr: [], //结算周期
      settlementStandardArr: [], //计算标准
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度需在30个字符之内", trigger: "blur" },
          { validator: validateSpecial, trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入任务单价", trigger: "blur" }],
        region1: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        taskDescribe: [
          { required: true, message: "请选择任务模板描述", trigger: "blur" },
        ],
      },
      pickerOptions0: {
        disabledDate: (time) => {
          if (this.ruleForm.value2 != "") {
            return (
              time.getTime() > new Date(this.ruleForm.value2) - 8.64e6 ||
              time.getTime() > new Date(this.value2) ||
              time.getTime() < Date.now() - 8.64e7 ||
              time.getTime() < new Date(this.value1) - 8.64e7
            );
          } else {
            return (
              time.getTime() < Date.now() - 8.64e7 ||
              time.getTime() < new Date(this.value1) - 8.64e7 ||
              time.getTime() > new Date(this.value2)
            );
          }
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < new Date(this.ruleForm.value1) - 8.64e7 ||
            time.getTime() > new Date(this.value2) ||
            time.getTime() < new Date(this.value1) - 8.64e7 ||
            time.getTime() < Date.now() - 8.64e7
          );
        },
      },
      contractId: "", //合同id
      // renwutype:[],
      msg: "哈哈",
      ifmsg: false,
      taskDescribeArr: [
        "因业务需要，现需若干人员通过线下完成快递、运输、配送等工作，我们希望您身体健康，做事积极，服从安排，工作认真负责，工作地点就近分配，我们希望您年龄在18~60岁之间，身体健康，做事积极，服从安排，工作认真负责，工作地点就近分配，信用记录良好，无犯罪记录",
        "因业务需要，现需若干人员通过线上+线下的方式完成线下探店推广、室内设计、产品体验等任务。我们希望您年龄在18~60岁之间，身体健康，做事积极，具备较高的专业素养，工作认真负责，信用记录良好，无犯罪记录",
        "因为业务需，现要若干人员通过线上完成推广运营、数据标注、文案编辑、电商美工、软件开发相关的任务，我们希望您年龄在18~60岁之间，身体健康，做事积极，专注专业，工作认真负责，信用记录良好，无犯罪记录",
      ], //模板数组
      isactive: 0,
    };
  },
  created() {
    this.getIndustry();
  },
  methods: {
    submitForm(formName) {
      console.log("lial ");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isDisable = true;
          createdTask({
            taskName: this.ruleForm.taskName,
            industryId: this.ruleForm.region1,
            contractId: this.contractId,
            // "industryCode": this.ruleForm.industryCode,
            // "ticketCategoryInfo": this.ruleForm.BillingType,
            taskStartTime: this.ruleForm.value1 + " 00:00:00",
            taskEndTime: this.ruleForm.value2 + " 23:59:59",
            taskType: "10001",
            settlementCycleId: this.ruleForm.settlementCycleId,
            settlementStandardId: this.ruleForm.settlementStandardId,
            price: this.ruleForm.price,
            taskDescribe: this.ruleForm.taskDescribe,
          }).then((data) => {
            this.isDisable = false;
            this.ifmsg = false; //msf提示
            if (data && data.code == 200) {
              this.$router.push("task-task");
              return;
            }
            if (data && data.code == 360001) {
              this.ifmsg = true;
              this.msg = "该任务名称已存在!";
              return;
            }
            if (data && data.code == 360002) {
              this.ifmsg = true;
              this.msg =
                "当前无生效中的合同，无法发布任务，请联系销售经理签署合同后重新发布！!";
              return;
            }
            this.$message.error(data.msg);
          });
        } else {
          return false;
        }
      });
    },
    //  下拉行业
    getIndustry() {
      Industry({}).then((data) => {
        if (data && data.code == 200) {
          this.contractId = data.data.contractId;
          this.value1 = data.data.contractStartTime;
          this.value2 = data.data.contractEndTime;
          //  this.Industrylist=data.data
        }
      });
      // // 任务类型
      //  getDownBoxByType({
      //   type:"MERCHANT_TASK_TYPE"
      // }).then((data=>{
      //  if (data && data.code==200) {
      //    this.renwutype=data.data
      //     }
      // }))
      //  结算周期
      getDownBoxByType({
        type: "MERCHANT_SETTLEMENT_CYCLE_TYPE",
      }).then((data) => {
        if (data && data.code == 200) {
          this.settlementCycleArr = data.data;
        }
      });
      // 结算标准
      getDownBoxByType({
        type: "MERCHANT_SETTLEMENT_TYPE",
      }).then((data) => {
        if (data && data.code == 200) {
          this.settlementStandardArr = data.data;
        }
      });
    },
    //  行业一级选择
    // Industrychange(){
    //     this.IndustrylistChild=[]
    //     this.ruleForm.region1=""
    //     if (this.ruleForm.region) this.dis=false //解除二级禁用
    //     this.Industrylist.map(item=>{
    //      if (item.scopeValue==this.ruleForm.region) {
    //         this.IndustrylistChild=item.industryItemVoList
    //         this.value1=item.contractStartTime
    //          this.value2=item.contractEndTime
    //       }
    //     })
    //  },
    // //  行业二级选择
    // IndustrychangeChild(){
    //  this.IndustrylistChild.map(item=>{
    //    console.log(item,"pp");
    //    if (item.industryValue==this.ruleForm.region1) {
    //      this.ruleForm.industryCode=item.industryCode
    //       this.ruleForm.BillingType=item.taxTape
    //    }
    //   })
    // },
    // 特殊字符
    checkSpecialKey(str) {
      let specialKey =
        "[`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    // 模板选择
    activeClick(index) {
      this.isactive = index;
      this.ruleForm.taskDescribe = this.taskDescribeArr[index];
    },
  },
};
</script>

<style  scoped  lang="scss">
.title {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  background: #ffff;
  margin-top: -20px;
  margin-left: -20px;
  width: 105%;
  text-indent: 20px;
}
.icon {
  font-size: 30px;
  transform: translateY(5px);
  color: #b5b5b5;
  margin-right: 5px;
  cursor: pointer;
}
.main {
  margin-top: 10px;
  .templateClass {
    display: inline-block;
    margin-left: 10px;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    padding: 15px;
    width: 200px;
    cursor: pointer;
    line-height: 20px;
  }
  .activecolor {
    color: #9e99b9;
  }
}
.type {
  height: 40px;
  width: 90px;
  background: #9e99b9;
  text-align: center;
  color: #ffff;
}
.tiltle {
  height: 30px;
  font-size: 14px;
}
</style>


